
import { VueperSlide } from 'vueperslides';
import { defineComponent, computed, ComputedRef, PropType } from 'vue';

import useCarousel from '@/use/useCarousel';
import { Product, Notification } from '@/modules/shop/types';
import { routeNames } from '@/modules/shop/router';

import Badge from '@/components/Badge/Badge.vue';
import Carousel from '@/components/Carousel/Carousel.vue';
import ProductCard from '@/modules/shop/components/ProductCard/ProductCard.vue';

import 'vueperslides/dist/vueperslides.css';

const visibleSlidesPerBreakpoint = {
  xl: 3.05,
  lg: 3.05,
  md: 2.1,
  sm: 1.05,
};

export default defineComponent({
  components: {
    VueperSlide,
    Carousel,
    ProductCard,
    Badge,
  },
  props: {
    filter: {
      type: Function as PropType<(Product) => boolean> | null,
      default: null,
    },
    products: {
      type: Object as PropType<Product[]>,
      default: () => [],
    },
    notifications: {
      type: Object as PropType<Notification[]>,
      default: () => [],
    },
    userPoints: {
      type: Number,
      required: true,
    },
    idRef: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const filteredProducts: ComputedRef<Product[]> = computed(() =>
      props.filter ? props.products.filter(props.filter) : props.products
    );

    const totalItems: ComputedRef<number> = computed(
      () => filteredProducts.value.length
    );

    const {
      hideLeftArrow,
      hideRightArrow,
      changeSlide,
      carouselBreakpointsConfig,
      visibleSlides,
      numberOfFillerSlides,
    } = useCarousel({
      visibleSlidesPerBreakpoint,
      totalItems,
    });

    return {
      changeSlide,
      filteredProducts,
      hideLeftArrow,
      hideRightArrow,
      carouselBreakpointsConfig,
      visibleSlides,
      numberOfFillerSlides,
      routeNames,
    };
  },
});
