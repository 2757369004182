
type FormValue = string | number[] | boolean | null;
type FormErrorMessage = string;
type ValidatorFunction = (valueToValidate: FormValue) => FormErrorMessage;

interface FormField {
  currentValue: FormValue;
  errorMessages?: FormErrorMessage[],
  readonly validators?: ValidatorFunction[],
}

export interface FormFieldDict {
  [fieldName: string]: FormField;
}

interface FormValueDict {
  [fieldName: string]: FormValue;
}

interface Form {
  readonly [fieldName: string]: FormField | (() => boolean) | (() => FormValueDict);
  readonly validate: () => boolean;
  readonly isValid: () => boolean;
  readonly getValues: () => FormValueDict;
}

const runValidators = (formField) => {
  formField.errorMessages = formField.validators
    .map(validate => validate(formField.currentValue))
    .filter(value => typeof value === 'string');
}

const isFormValidReducer = (acc: boolean, field: FormField): boolean => {
  return acc && field.errorMessages.length === 0
};

const validate = (formConfig: FormFieldDict) => {
  Object.values(formConfig).forEach(runValidators);
  return Object.values(formConfig)
    .reduce(isFormValidReducer, true);
}

const normaliseField = (field: FormField): FormField => ({
  currentValue: field.currentValue || null,
  errorMessages: field.errorMessages || [],
  validators: field.validators || [],
})

const normaliseForm = (form: FormFieldDict): FormFieldDict => {
  Object.keys(form).forEach((fieldKey) => {
    form[fieldKey] = normaliseField(form[fieldKey]);
  });
  return form;
};

const getValues = (form: FormFieldDict): FormValueDict =>
  Object.keys(form).reduce((valuesObj, fieldName) => ({
    ...valuesObj,
    [fieldName]: form[fieldName].currentValue,
  }), {});

export default (formConfig: FormFieldDict): Form => {
  const normalisedForm = normaliseForm(formConfig);
  return {
    ...normalisedForm,
    validate: () => validate(formConfig),
    isValid: () => validate(formConfig),
    getValues: () => getValues(normalisedForm),
  };
};
