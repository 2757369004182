import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hero-slider-carousel overflow-hidden pb-16 sm:pb-0 md:pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSliderCarouselItem = _resolveComponent("HeroSliderCarouselItem")!
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_VueperSlides = _resolveComponent("VueperSlides")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.items.length)
      ? (_openBlock(), _createBlock(_component_VueperSlides, {
          key: 0,
          arrows: true,
          touchable: "",
          fixedHeight: _ctx.sliderHeight,
          draggingDistance: 30,
          autoplay: "",
          duration: 3500,
          pauseOnHover: "",
          pauseOnTouch: ""
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (classItem) => {
              return (_openBlock(), _createBlock(_component_VueperSlide, {
                key: classItem.id
              }, {
                content: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_HeroSliderCarouselItem, {
                    key: classItem.id,
                    title: classItem.title,
                    image: classItem.image,
                    subtitle: classItem.headline,
                    color: classItem.theme,
                    buttonName: classItem.cta_name,
                    url: classItem.cta_link
                  }, null, 8, ["title", "image", "subtitle", "color", "buttonName", "url"]))
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["fixedHeight"]))
      : _createCommentVNode("", true)
  ]))
}