
import { computed, ref, defineComponent, ComputedRef } from 'vue';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';

import { Product } from '@/modules/shop/types';
import { PointsAndSortFilters } from './ProductsFilters.types';

import BannerWithButton from '@/components/BannerWithButton/BannerWithButton.vue'
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import Icon from '@/components/Icon/Icon.vue';


const FILTERS_APPLIED_EVENT = 'filters-applied';

export default defineComponent({
  components: { BannerWithButton, Button, Icon },
  props: {
    products: {
      type: Object as () => Product[],
      default: () => [],
    },
    isDisplayingNoProducts: {
      type: Boolean,
      default: false,
    }
  },
  emits: [FILTERS_APPLIED_EVENT],
  setup(props, { emit }) {
    const showFiltersView = ref(false);
    const toggleFiltersView = () =>
      (showFiltersView.value = !showFiltersView.value);
    const filtersCategory: ComputedRef<string[]> = computed(() =>
      uniq(props.products.map((product) => product.category))
    );

    const filtersPoints = [
      { name: '0 - 100 pt', value: 'RANGE-1' },
      { name: '100 - 300 pt', value: 'RANGE-2' },
      { name: '300 - 500 pt', value: 'RANGE-3' },
      { name: 'Mehr als 500 pt', value: 'RANGE-4' },
    ];

    const filterSortBy = [
      { name: 'Empfehlungen', value: 'DEFAULT' },
      { name: 'IQOS Points - Niedrig bis hoch', value: 'INCREASING' },
      { name: 'IQOS Points - Hoch bis niedrig', value: 'DECREASING' },
    ];

    const currentCategoriesApplied = ref([]);
    const currentPointsApplied = ref([]);
    const currentSortApplied = ref<PointsAndSortFilters | undefined>(
      filterSortBy[0]
    );

    const showSortChip = computed(
      () =>
        Object.keys(currentSortApplied.value).length &&
        currentSortApplied.value.value !== 'DEFAULT'
    );

    const filterButtonClick = () => {
      categoryVModel.value = currentCategoriesApplied.value;
      pointsVModel.value = currentPointsApplied.value;
      sortByVModel.value = currentSortApplied.value;
      toggleFiltersView();
    };

    const resetFilters = () => {
      categoryVModel.value = [];
      pointsVModel.value = [];
      sortByVModel.value = filterSortBy[0];
    };

    const resetAllFiltersAndUpdate = () => {
      resetFilters();
      currentCategoriesApplied.value = [];
      currentPointsApplied.value = [];
      // Propagate the changes
      emitFiltersApplied()
    }

    const removeCategoryFilter = (filterToRemove) => {
      remove(
        currentCategoriesApplied.value,
        (filter) => filter === filterToRemove
      );
      emitFiltersApplied()
    };

    const removePointFilter = (filterToRemove) => {
      remove(
        currentPointsApplied.value,
        (filter) => filter.value === filterToRemove
      );
      emitFiltersApplied()
    };

    const removeSortingFilter = () => {
      (currentSortApplied.value = { name: 'Default', value: 'DEFAULT' }),
      emitFiltersApplied()
    };

    const sortByVModel = ref<PointsAndSortFilters | undefined>({});
    const categoryVModel = ref<string[]>(['Freizeit']);
    const pointsVModel = ref<PointsAndSortFilters[] | undefined[]>([]);

    const isCategorySelected = (filter) =>
      categoryVModel.value.includes(filter);
    const isPointsSelected = (filter) =>
      pointsVModel.value.some(
        (selected: PointsAndSortFilters) => selected?.value === filter
      );

    /**
     * @name productsFilteredByCategory
     * @description Filter all products by category selected
     * @return {Array} Array of filtered products by categories
     */
    const productsFilteredByCategory: ComputedRef<Product[]> = computed(() =>
      props.products.filter((product) =>
        categoryVModel.value.includes(product.category)
      )
    );

    /**
     * @name productsFilteredByPoints
     * @description Filter all products by point range selected
     * @return {Array} Array of filtered products by points
     */
    const productsFilteredByPoints: ComputedRef<Product[]> = computed(() =>
      props.products.filter(
        (product) =>
          pointsVModel.value.filter((points: PointsAndSortFilters) =>
            arePointsInRange(product.requiredPoints, points.value)
          ).length
      )
    );

    /**
     * @name arePointsInRange
     * @description Check if points are in range with the filter applied
     * @param {Number} points Product point
     * @param {String} filter filter selected
     * @returns {Boolean}
     */
    const arePointsInRange = (points: number, filter: string): boolean =>
      ({
        'RANGE-1': points <= 100,
        'RANGE-2': points >= 100 && points <= 300,
        'RANGE-3': points >= 300 && points <= 500,
        'RANGE-4': points >= 500,
        default: false,
      }[filter || 'default']);

    /**
     * @name productsFilteredByBoth
     * @description This function keeps the products that are present in both filters(category, points)
     * @return {Array} Array of products
     */
    const productsFilteredByBoth: ComputedRef<Product[]> = computed(() => {
      const mergeFilteredProduct = [
        ...productsFilteredByCategory.value,
        ...productsFilteredByPoints.value,
      ];
      const productsWithBothFilters = [];

      mergeFilteredProduct.reduce((acc, product) => {
        acc[product.productId] = ++acc[product.productId] || 0;
        acc[product.productId] > 0 && productsWithBothFilters.push(product);
        return acc;
      }, {});
      return productsWithBothFilters;
    });

    /**
     * @name finalFilteredProducts
     * @description We decide which filters we have to apply( only categories, only points or both)
     * @return {Array} Array of products
     */
    const finalFilteredProducts: ComputedRef<Product[]> = computed(() =>
      Object.keys(currentCategoriesApplied.value).length &&
      currentPointsApplied.value.length
        ? productsFilteredByBoth.value
        : productsFilteredByPoints.value.length
        ? productsFilteredByPoints.value
        : productsFilteredByCategory.value
    );

    const applySorting = (type: string, list: Product[]) => {
      const sorting = sortBy([...list], 'requiredPoints');
      const sortingMap = {
        INCREASING: sorting,
        DECREASING: [...sorting].reverse(),
        default: list,
      };
      const isSortExisting = Object.keys(sortingMap).includes(type)
        ? type
        : 'default';
      return sortingMap[isSortExisting];
    };

    const hasFilters: ComputedRef<number> = computed(
      () =>
        currentCategoriesApplied.value.length ||
        currentPointsApplied.value.length
    );

    const emitFiltersApplied = () => emit(FILTERS_APPLIED_EVENT, sortProducts.value, hasFilters);

    const sortProducts: ComputedRef<Product[]> = computed(() =>
      hasFilters.value
        ? applySorting(
            currentSortApplied.value.value,
            finalFilteredProducts.value
          )
        : applySorting(currentSortApplied.value.value, props.products)
    );

    const applyFilters = (): void => {
      currentCategoriesApplied.value = categoryVModel.value;
      currentPointsApplied.value = pointsVModel.value;
      currentSortApplied.value = sortByVModel.value;

      emitFiltersApplied()
      toggleFiltersView();
    };

    // True only when we should display the reset filters button when there
    // are no products being listed.
    // This prevents displaying the reset filters button in the initial render
    const isZeroProductsWithFilters: ComputedRef<boolean> = computed(() => {
      const noFilterIsApplied = currentCategoriesApplied.value.length !== 0
        && currentPointsApplied.value.length !== 0;
      return props.isDisplayingNoProducts && noFilterIsApplied
    })
    return {
      BUTTON_THEMES,
      applyFilters,
      categoryVModel,
      currentCategoriesApplied,
      currentPointsApplied,
      currentSortApplied,
      filterButtonClick,
      filtersCategory,
      filtersPoints,
      filterSortBy,
      showSortChip,
      hasFilters,
      isCategorySelected,
      isPointsSelected,
      isZeroProductsWithFilters,
      pointsVModel,
      resetFilters,
      resetAllFiltersAndUpdate,
      removeCategoryFilter,
      removePointFilter,
      removeSortingFilter,
      sortByVModel,
      showFiltersView,
      toggleFiltersView,
    };
  },
});
