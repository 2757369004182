/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ref, ComputedRef } from 'vue';
import useBreakpoints from '@/use/useBreakpoints';

interface VisibleSlidesConfig {
  xl: number;
  lg: number;
  md: number;
  sm: number;
}

interface CarouselBreakpointsConfig {
  [key: number]: { [key: string]: any}
}
interface UseCarousel {
  hideLeftArrow: ComputedRef<boolean>,
  hideRightArrow: ComputedRef<boolean>,
  changeSlide: (slide: any) => void,
  carouselBreakpointsConfig: CarouselBreakpointsConfig,
  visibleSlides: ComputedRef<number>,
  numberOfFillerSlides: ComputedRef<number>,
}


export default ({
  visibleSlidesPerBreakpoint,
  carouselBreakpoints = null,
  totalItems,
}: {
  visibleSlidesPerBreakpoint: VisibleSlidesConfig,
  totalItems: ComputedRef<number>,
  carouselBreakpoints?: CarouselBreakpointsConfig | null,
}): UseCarousel => {
  const breakpointsService = useBreakpoints();
  const { breakpoints } = breakpointsService;
  const currentSlide = ref(0);
  const isMobile = !breakpointsService.isMd();

  const visibleSlides = computed(() =>
    breakpointsService.isXl()
    ? visibleSlidesPerBreakpoint.xl
    : breakpointsService.isLg()
    ? visibleSlidesPerBreakpoint.lg
    : breakpointsService.isMd()
    ? visibleSlidesPerBreakpoint.md
    : visibleSlidesPerBreakpoint.sm
  );

  // VueperSlides applies breakpoints config from largest width to smallest. Sorting from 99999 to the smallest available
  const carouselBreakpointsConfig = carouselBreakpoints || {
    9999: { // larger than lg
      visibleSlides: visibleSlidesPerBreakpoint.xl,
      touchable: false,
      initSlide: 2,
    },
    [breakpoints.xl - 1]: { // lg
      visibleSlides: visibleSlidesPerBreakpoint.lg,
      touchable: false,
      initSlide: 2,
    },
    [breakpoints.lg - 1]: { // md
      visibleSlides: visibleSlidesPerBreakpoint.md,
      touchable: false,
      initSlide: 2,
    },
    [breakpoints.md - 1]: { // sm and lower
      visibleSlides: visibleSlidesPerBreakpoint.sm,
      touchable: isMobile,
      initSlide: 1,
    },
  };

  /*
      fill the carousel with enough items to make the carousel even (filled until the end/right side) + 1 extra slide to shift the carousel a bit more and keep the alignment with the container
      for example: for 11 items and 3 visibleSlides we add 1 to make 12 in total
      + 1 is for the extra item to push the slides to the left. Aligning them to the container.
    */
  const numberOfFillerSlides = computed(() => {
    if (visibleSlides.value <= 2) return 0
    const actualVisibleSlides = Math.floor(visibleSlides.value);
    const isEven = totalItems.value % actualVisibleSlides === 0;
    const enoughItemsToFillTheVisibleItems = actualVisibleSlides - (totalItems.value % actualVisibleSlides) + 1
    return isEven ? 1 : enoughItemsToFillTheVisibleItems
  });

  const changeSlide = (slide) => {
    currentSlide.value = slide.currentSlide.index;
  };

  const hideLeftArrow = computed(
    () =>
      isMobile
      || currentSlide.value < Math.floor(visibleSlides.value) - 1
      || currentSlide.value  === 0
  );

  const offsetFixesPerVisibleSlides = {
    1: 0,
    2: 0,
    3: 0,
    4: -1,
  }

  /*

  The goal here is to define the virtual end of the carousel so that the arrow is hidden when the last item is show to the user(when is in the viewport).

  In depth explanation:
  To hide the right arrow is necessary to know when the active slide index will reach the "last page" with all the last slides visible to the user.

  VueperSlides changes the position of the active slide based on the visibleSlides as found here:
  https://github.com/antoniandre/vueper-slides/blob/master/src/components/vueperslides/vueperslides.vue#L318-L326

  For that reason is necessary to offset the end of the carousel per each visible slides config. The other option is to reuse the same logic used in the code mentioned above but that would increase the complexity and is better to set manually per each config.

  when to hide the arrow example:
    for 3 visibleSlides slides:
      | ... | -> range that is visible to the user. Let's call this a page.
      []      -> carousel item
      [a]     -> active carousel item

      | [], [a], [], | [], [] -> arrow right is shown
      [], | [], [a], [], | [] -> arrow right is shown
      [], [],| [], [a], [] | -> arrow right is NOT shown on the last page.

  The arrow is hidden before the current index reaches the last slide, but still on the last page.
  */
  const hideRightArrow = computed(
    () => {
      const actualVisibleSlides = Math.floor(visibleSlides.value);
      const fixOffsetFromVueperslides = offsetFixesPerVisibleSlides[actualVisibleSlides];
      return isMobile
        || totalItems.value === actualVisibleSlides
        || currentSlide.value > totalItems.value - actualVisibleSlides - fixOffsetFromVueperslides
    })

  return {
    hideLeftArrow,
    hideRightArrow,
    changeSlide,
    carouselBreakpointsConfig,
    visibleSlides,
    numberOfFillerSlides,
  };
};
