
import { defineComponent, computed, PropType } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import useBreakpoints from '@/use/useBreakpoints';

import { AdvertisingTileType } from '@/modules/shop/types';

import AdvertisingTile from './AdvertisingTile.vue';

export default defineComponent({
  components: {
    AdvertisingTile,
    VueperSlides,
    VueperSlide,
  },
  props: {
    tiles: {
      type: Array as PropType<AdvertisingTileType[]>,
      required: true,
    },
  },
  setup() {
    const breakpoints = useBreakpoints();
    const sliderHeight = computed(() =>
      breakpoints.isMd() ? '500px' : '522px'
    );

    return {
      sliderHeight,
    };
  },
});
