
import { defineComponent, PropType } from 'vue';

const EVENT_COLOR_CHANGE = 'colorChange';

export default defineComponent({
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    xl: {
      type: Boolean,
      default: false,
    },
    currentColorIndex: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      default: '',
    }
  },
  emits: [EVENT_COLOR_CHANGE],
  setup(props, { emit }) {

    const changeColor = (index: string) => {
      emit(EVENT_COLOR_CHANGE, index);
    };
    return {
      changeColor,
    }
  }
})
