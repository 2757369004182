
import { defineComponent, onMounted, ref } from 'vue';

const INPUT = 'input';

export default defineComponent({
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Hier auswählen',
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const open = ref(false);
    let selected = ref();

    // selected.value = props.default
    //   ? props.default
    //   : props.options.length > 0
    //   ? props.options[0]
    //   : null;

    onMounted(() => {
      // emit(INPUT, selected.value);
    });

    const onClickOption = (option) => {
      selected.value = option;
      open.value = false;
      emit(INPUT, option);
    };

    return { selected, open, onClickOption };
  },
});
