
import { defineComponent } from 'vue';

import FAQs from './faqs';
import Accordion from '@/components/Accordion/Accordion.vue';

export default defineComponent({
  components: {
    Accordion,
  },
  setup() {
    return {
      FAQs,
    };
  },
});
