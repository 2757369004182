import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkout = _resolveComponent("Checkout")!

  return (_ctx.product && _ctx.consumer)
    ? (_openBlock(), _createBlock(_component_Checkout, {
        key: 0,
        product: _ctx.product,
        additionalProduct: _ctx.additionalProduct,
        variant: _ctx.variant,
        orderAction: _ctx.orderAction,
        consumer: _ctx.consumer,
        loyaltyPoints: _ctx.loyaltyPoints,
        networkError: _ctx.networkError
      }, null, 8, ["product", "additionalProduct", "variant", "orderAction", "consumer", "loyaltyPoints", "networkError"]))
    : _createCommentVNode("", true)
}