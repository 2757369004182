import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/arrow-down.svg'


const _hoisted_1 = { class: "product-description" }
const _hoisted_2 = { class: "text-fs-28-lh-40-ls-0 md:text-fs-36-lh-48-ls-0 xl:text-fs-42-lh-56-ls-0 font-bold mb-8" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "text-sm pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.product.name), 1),
    (_ctx.shouldReadMore)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "product-description__rich-text mb-2",
          innerHTML: _ctx.product.description
        }, null, 8, _hoisted_3))
      : (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "product-description__rich-text mb-2",
          innerHTML: _ctx.showReadMore ? _ctx.getProductDescription() : _ctx.product.description
        }, null, 8, _hoisted_4)),
    (_ctx.showReadMore)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          id: `DCE_product_description_readMore`,
          class: "flex cursor-pointer border-b border-primary-slate",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleReadMore && _ctx.toggleReadMore(...args)))
        }, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.shouldReadMore ? 'Lese weniger' : 'Mehr lesen'), 1),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "arrow down",
            class: _normalizeClass(["product-description__down-arrow w-4 h-4 pointer-events-none", { 'transform rotate-180': _ctx.shouldReadMore }])
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}