import {
  ActionField,
  DataType,
  EventsType,
  ImpressionFieldObject,
  ProductClickObject,
  ProductFieldObject,
} from './types'
import { ProductTypesEnum } from '@/modules/shop/types';

// We need yo send an EUR currency. 1 point = 0,10 (10 cents)
export const pointsInEuro = (points: number): number => {
  const calcPointInEuro = 0.10 * points
  return Number(calcPointInEuro.toFixed(2))
}

export const brandGTM = (type: string): string => type === ProductTypesEnum.IQOS_DEVICE
  ? 'IQOS'
  : 'Third Party'

/**
 * @name productFieldDefault
 * @description Product Data: Product data represents individual products that were viewed, added to the shopping cart, etc.
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#product-data
 */
export const productFieldDefault: ProductFieldObject = {
  name: '',
  id: '',
  price: null,
  brand: 'Third Party',
  category: '',
  variant: '',
  availability: ''
}
/**
 * @name productClickDefault
 * @see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#product-clicks
 */
export const productClickDefault: ProductClickObject = {
  ...productFieldDefault,
  position: null
}
/**
 * @name impressionFieldDefault
 * @description Impression Data: Represents information about a product that has been viewed
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#impression-data
 */
export const impressionFieldDefault: ImpressionFieldObject = {
  ...productFieldDefault,
  list: '',
  position: null
}

export const actionFieldDefault: ActionField = {
  id: '',
  affiliation: 'Rewardshop',
  revenue: '',
}

export const pushDataLayer = (event: EventsType, attrs: DataType): void => {

  const pushHandler = {
    productPurchase: {
      event: 'transactionComplete',
      ecommerce: {
        purchase: {
          actionField: attrs.actionFieldPurchase,
          products: attrs.productList
        },
      }
    },
    productImpression: {
      ecommerce: {
        currencyCode: 'EUR',
        impressions: attrs.productImpressionList
      }
    },
    productClick: {
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: attrs.list },
          products: attrs.productList
        }
      }
    },
    productDetail: {
      event: 'productDetailView',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: attrs.productImpressionList,
        detail: {
          actionField: { list: attrs.list },
          products: attrs.productList
        },
      }
    },
    productAddCart: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: attrs.productList
        }
      }
    },
    productCheckout: {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Points' },
          products: attrs.productList
        }
      }
    },
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push(pushHandler[event])
}
