import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDetails = _resolveComponent("ProductDetails")!

  return (_ctx.product)
    ? (_openBlock(), _createBlock(_component_ProductDetails, {
        key: `${_ctx.variantId}`,
        product: _ctx.product,
        products: _ctx.products,
        userPoints: _ctx.userPoints,
        checkoutUrl: _ctx.checkoutUrl,
        variantId: `${_ctx.variantId}`,
        currentTier: _ctx.tierLevel,
        onSelectVariant: _ctx.handleVariantSelected
      }, null, 8, ["product", "products", "userPoints", "checkoutUrl", "variantId", "currentTier", "onSelectVariant"]))
    : _createCommentVNode("", true)
}