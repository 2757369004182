import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8fbe62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-category-selector border-b border-gray-300" }
const _hoisted_2 = { class: "product-category-selector__container flex flex-row flex-nowrap overflow-x-auto lg:w-screen mx-auto xl:w-auto" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCategories, (category, i) => {
        return (_openBlock(), _createElementBlock("button", {
          key: i,
          class: _normalizeClass(["product-category text-fs-16-lh-24-ls-0.2 w-auto lg:flex-grow", {
          'font-bold border-b-4 border-primary-slate': _ctx.selected === i,
        }]),
          onClick: ($event: any) => (_ctx.selectCategory(i))
        }, _toDisplayString(category.name), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}