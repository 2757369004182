
import { defineComponent } from 'vue';

import { ResponsiveImageSet } from '@/types';
import Link from '@/components/Link/Link.vue';
import Badge from '@/components/Badge/Badge.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

export default defineComponent({
  components: {
    ResponsiveImage,
    Badge,
    Link,
  },
  props: {
    image: {
      type: Object as () => ResponsiveImageSet,
      required: true,
    },
    points: {
      type: [String, Number],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Coming soon',
    },
    category: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
  },
});
