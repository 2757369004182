import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderConfirmation = _resolveComponent("OrderConfirmation")!

  return (_ctx.product && _ctx.variant)
    ? (_openBlock(), _createBlock(_component_OrderConfirmation, {
        key: 0,
        product: _ctx.product,
        additionalProduct: _ctx.additionalProduct,
        variant: _ctx.variant,
        attributesWithIcons: _ctx.attributesWithIcons,
        loyaltyPoints: _ctx.loyaltyPoints,
        order: _ctx.order,
        shippingAddress: _ctx.shippingAddress,
        consumer: _ctx.consumer
      }, null, 8, ["product", "additionalProduct", "variant", "attributesWithIcons", "loyaltyPoints", "order", "shippingAddress", "consumer"]))
    : _createCommentVNode("", true)
}