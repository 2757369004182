
import { defineComponent } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

const EVENT_CLOSE_OVERLAY = 'onCloseOverlay';

export default defineComponent({
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    initSlide: {
      type: String,
      default: '',
    },
    sliderImages: {
      type: Array,
      default: () => [],
    },
  },
  emits: [EVENT_CLOSE_OVERLAY],
  setup() {
    return {
      EVENT_CLOSE_OVERLAY,
    }
  }
});
