
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    cardColor: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    voucherCode: {
      type: String,
      default: ''
    },
  },
});
