<template>
  <div class="checkout-card">
     <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.checkout-card {
  @apply w-full
         px-m pt-l pb-xxl-4
         bg-primary-soft-white;
  @screen md {
    width: 464px;
    @apply px-xxl-4;
  }
  @screen lg {
    width: 416px;
    @apply px-xl;
  }
}
</style>