
import { defineComponent, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { Product, Variant, PRODUCT_TYPES, Order } from '@/modules/shop/types';

import OrderConfirmation from './OrderConfirmation.vue';
import { Consumer } from '@/modules/consumer/types';

type AttributeWithIcon = { text: string; icon: string };

export default defineComponent({
  components: {
    OrderConfirmation,
  },
  setup() {
    const store = useStore();

    store.dispatch('shopModule/getConfig');
    store.dispatch('consumerModule/getConsumer');

    const consumer: ComputedRef<Consumer> = computed(
      () => store.state.consumerModule.consumer
    );
    const loyaltyPoints: ComputedRef<number> = computed(
      () => store.getters['consumerModule/loyaltyPoints']
    );
    const order: ComputedRef<Order> = computed(
      () => store.state.shopModule.order
    );
    const shippingAddress = computed(
      () => store.state.shopModule.customerInfo?.shippingAddress
    );
    const additionalProduct = computed(
      () => store.state.shopModule.additionalProduct
    );

    const product: ComputedRef<Product> = computed(
      () => store.state.shopModule.currentProduct
    );

    const variant: ComputedRef<Variant> = computed(
      () => store.state.shopModule.currentVariant
    );

    const attributes: ComputedRef<string[]> = computed(
      () =>
        store.state.shopModule.config?.attributes?.[product.value.type] || []
    );

    const getIconTypeByIndex = (index: number): string => {
      const typesMap = {
        [PRODUCT_TYPES.ITG]: ['coach', 'mail', 'info'],
        // We don't need more than info and check icons since we won't have
        // more than 2 attributes for voucher
        [PRODUCT_TYPES.VOUCHER]: ['info', 'check'],
      };
      const types = typesMap[product.value.type];
      // Repeat after icon types after third element
      return types[index] || types[2];
    };

    const attributesWithIcons: ComputedRef<AttributeWithIcon[]> = computed(() =>
      attributes.value
        // remove empty attributes, check PMIDCE-383 for more info
        .filter((attrText) => attrText != '')
        .map((attrText, index) => ({
          text: attrText,
          icon: getIconTypeByIndex(index),
        }))
    );

    return {
      attributesWithIcons,
      consumer,
      loyaltyPoints,
      order,
      product,
      shippingAddress,
      variant,
      additionalProduct,
    };
  },
});
