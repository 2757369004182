import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createBlock(_component_Breadcrumbs, {
    breadcrumbs: _ctx.breadcrumbs,
    breadcrumbLastItem: _ctx.breadcrumbLastItem,
    userPoints: _ctx.userPoints,
    isCheckoutPage: _ctx.isCheckoutPage,
    currentProduct: _ctx.currentProduct,
    currentClass: _ctx.currentClass,
    currentVariant: _ctx.currentVariant,
    routeName: _ctx.$route.meta.routeBreadcrumbName
  }, null, 8, ["breadcrumbs", "breadcrumbLastItem", "userPoints", "isCheckoutPage", "currentProduct", "currentClass", "currentVariant", "routeName"]))
}