
import { defineComponent } from 'vue';

import { ResponsiveImageSet } from '@/types';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';

import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';

export default defineComponent({
  components: {
    ResponsiveImage,
    Button,
  },
  props: {
    image: {
      type: Object as () => ResponsiveImageSet,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Coming soon',
    },
    buttonName: {
      type: String,
      default: '',
    },
  },
  computed: {
    textColorClass() {
      return this.color === 'light'
        ? 'text-primary-soft-white'
        : 'text-primary-dark';
    },
    buttonTheme() {
      return this.color === 'light' ? BUTTON_THEMES.DARK : BUTTON_THEMES.LIGHT;
    },
  },
});
