import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    id: _ctx.id,
    class: "redeem-button",
    disabled: _ctx.buttonIsDisabled,
    alternateHoverAmber: _ctx.showUnitsLeft,
    alternateHoverDeepRed: _ctx.showDiscount
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
    ]),
    _: 1
  }, 8, ["id", "disabled", "alternateHoverAmber", "alternateHoverDeepRed"]))
}