export const teasers = [
  {
    category: 'IQOS ILUMA Onboarding',
    id: '1',
    index: 0,
    points: 40,
    color: 'bg-primary-turquoise',
    subtitle: 'Challenge abschließen und Gratis TEREA Packung sichern.*',
    title: 'IQOS Club Academy',
    url: 'https://de.iqosclub.com/academy',
    image: {
      alt: 'IQOS rewardshop',
      desktop: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/iqos-academy.png'),
        x2: require('@/assets/shop/product-listing/iqos-academy@2x.png'),
        x3: require('@/assets/shop/product-listing/iqos-academy@3x.png'),
      },
    },
    info: '* Nur solange der Vorrat reicht.',
  },
  {
    category: 'Entdecke die Welt um IQOS',
    id: '2',
    index: 1,
    points: '30 Statuspunkte',
    color: 'bg-secondary-amber',
    subtitle: 'Neue Stories entdecken und \n Statuspunkte sichern.',
    title: 'IQOS CLUB Stories',
    url: 'https://de.iqosclub.com/stories',
    image: {
      alt: 'IQOS stories',
      desktop: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/img-stories.png'),
        x2: require('@/assets/shop/product-listing/img-stories@2x.png'),
        x3: require('@/assets/shop/product-listing/img-stories@3x.png'),
      },
    },
  },
  {
    category: '',
    id: '3',
    index: 2,
    points: 100,
    color: 'bg-primary-turquoise',
    subtitle:
      'Empfehle IQOS Deinen Freunden<sup>1</sup> und erhalte für Deine erste Empfehlung eine Art Box mit 5x TEREA Limited Pack Editions<sup>2</sup> sowie 10 € in Form von 100 IQOS Points<sup>3</sup>.',
    title: 'Kunst genießt man am besten mit Freunden.',
    url: 'https://www.iqos.com/de/de/freunde-empfehlen.html',
    image: {
      alt: 'Kunst genießt man am besten mit Freunden.',
      desktop: {
        x1: require('@/assets/shop/product-listing/terea-10-years.png'),
        x2: require('@/assets/shop/product-listing/terea-10-years@2x.png'),
        x3: require('@/assets/shop/product-listing/terea-10-years@3x.png'),
      },
      mobile: {
        x1: require('@/assets/shop/product-listing/terea-10-years.png'),
        x2: require('@/assets/shop/product-listing/terea-10-years@2x.png'),
        x3: require('@/assets/shop/product-listing/terea-10-years@3x.png'),
      },
    },
    info: '<sup>1</sup> Nur für erwachsene Raucher.<br/><br/><sup>2</sup> Die Box inklusive 5 TEREA Packs erhältst Du einmalig zu Deiner ersten erfolgreichen Empfehlung im Zeitraum vom 02.09. bis 03.11.2024. Für jede weitere erfolgreiche Empfehlung erhältst Du 100 IQOS Points – ohne Box. Der Geworbene erhält seine Box, wenn er IQOS Neukunde ist und er sich mit Deinem IQOS Freundschaftscode ein IQOS ILUMA oder IQOS ILUMA PRIME kauft und sich erstmalig in der IQOS Datenbank registriert. Nur einlösbar mit einem IQOS Freundschaftscode von IQOS Nutzern auf http://IQOS.com, in IQOS Stores, bei IQOS Verkaufsexperten oder autorisierten Händlern. Nur solange der Vorrat reicht.<br/><br/><sup>3</sup> Hat sich der Geworbene mit Deinem Freundschaftscode ein IQOS ILUMA oder IQOS ILUMA PRIME gekauft, erhältst Du für jede erfolgreiche Registrierung 10 € in Form von 100 IQOS Points, die Du für Prämien wie Wunschgutschein, JET-Tank- oder Spotify-Wertgutschein im IQOS CLUB eintauschen kannst. Die Nutzung des Freundschaftscodes für kommerzielle Zwecke und eine Verbreitung auf eBay sind untersagt. Zudem ist die Nutzung des Freundschaftscodes auf 50 erfolgreiche Empfehlungen limitiert.',
  },
];
