import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType), _mergeProps({ ..._ctx.linkAttr }, {
    class: [
      'button-link',
      _ctx.fontColorClasses,
      { 'button-link--hover': _ctx.isHover },
    ],
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHoverState(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHoverState(false)))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("span", {
        class: _normalizeClass(["block w-full h-px", [
        _ctx.underlineColorClasses,
        {
          'bg-primary-turquoise': _ctx.isHover,
        },
      ]])
      }, null, 2)
    ]),
    _: 3
  }, 16, ["class"]))
}