
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import Icon from '@/components/Icon/Icon.vue';

const getCircleColorByStep = (setupStep, currentStep) => ({
  'bg-background-hard-white border-2 border-tints-slate-50': currentStep < setupStep,
  'bg-primary-slate': currentStep === setupStep,
  'bg-primary-turquoise': currentStep > setupStep,
});

const getNumberColorByStep = (setupStep, currentStep) => ([
  currentStep < setupStep
    ? 'text-tints-slate-50'
    : 'text-primary-soft-white'
]);

export default defineComponent({
  components: { Icon },
  props: {
    step: {
      type: Object as PropType<number | string>,
      default: 1,
      validator: (value: number) => [1,2,3].includes(value),
    },
    isShippableProduct: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const stepNumber: ComputedRef<number> = computed(() => parseInt('' + props.step));
    return {
      stepNumber,
      getCircleColorByStep,
      getNumberColorByStep,
    }
  }
});
