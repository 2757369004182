
import { defineComponent, computed } from 'vue';
import Icon from '@/components/Icon/Icon.vue';

import { getProductDetailUrl } from '@/modules/shop/router';

import { Product, Variant } from '@/modules/shop/types';
import { DeprecatedAcademyClass } from '@/modules/academy/types';
import { Breadcrumb } from './Breadcrumbs.types';

export default defineComponent({
  components: { Icon },
  props: {
    breadcrumbs: {
      type: Object as () => Breadcrumb[],
      default: () => [],
    },
    breadcrumbLastItem: {
      type: String,
      default: '',
    },
    userPoints: {
      type: Number,
      default: null,
    },
    isCheckoutPage: {
      type: Boolean,
      default: false,
    },
    currentProduct: {
      type: Object as () => Product,
      default: null,
    },
    currentClass: {
      type: Object as () => DeprecatedAcademyClass,
      default: null,
    },
    currentVariant: {
      type: Object as () => Variant,
      default: null,
    },
    routeName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const productUrl = computed(
      () =>
        props.currentProduct &&
        getProductDetailUrl(
          props.currentProduct.productId,
          props.currentVariant.variantId
        )
    );
    const breadcrumbsMobile = computed(() => {
      return props.isCheckoutPage
        ? props.currentProduct && {
            name: props.currentProduct.name,
            link: productUrl.value,
          }
        : props.breadcrumbs && props.breadcrumbs[props.breadcrumbs.length - 1];
    });

    return {
      productUrl,
      breadcrumbsMobile,
    };
  },
});
