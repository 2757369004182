
import { computed, defineComponent, PropType, ref } from 'vue';
import useBreakpoints from '@/use/useBreakpoints';
import 'vueperslides/dist/vueperslides.css';
import { VueperSlides } from 'vueperslides';

/*
  All the $attrs will be passed down to the VueperSlides component
  to keep the same API as that component.
*/
export default defineComponent({
  components: {
    VueperSlides,
  },
  props: {
    hideLeftArrow: {
      type: Boolean,
      default: false,
    },
    hideRightArrow: {
      type: Boolean,
      default: false,
    },
    leftArrowIcon: {
      type: String,
      default: require('@/assets/svgs/button-arrow-left-dark-theme.svg'),
    },
    rightArrowIcon: {
      type: String,
      default: require('@/assets/svgs/button-arrow-right-dark-theme.svg'),
    },
    customArrowPosition: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object as PropType<{
        [key: number]: { [key: string]: number };
      }>,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 4000,
    },
    preventYScroll: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const breakpointsService = useBreakpoints();
    const { breakpoints } = breakpointsService;

    const vueperRef = ref();

    // VueperSlides applies breakpoints config from largest width to smallest. Sorting from 99999 to the smallest available
    const computedBreakpointsConfig = computed(
      () =>
        props.breakpoints || {
          9999: { visibleSlides: 3.15 },
          [breakpoints.lg - 1]: { visibleSlides: 2 },
          [breakpoints.md - 1]: { visibleSlides: 1.1 },
        }
    );

    return {
      computedBreakpointsConfig,
      vueperRef,
    };
  },
});
