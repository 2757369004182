
import { defineComponent, computed, ref, Ref } from 'vue';

export const LINK_BUTTON_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

const LINK_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

const attrToLinkTypeMap = {
  [LINK_TYPES.INTERNAL]: { component: 'RouterLink', attr: 'to' },
  [LINK_TYPES.EXTERNAL]: { component: 'a', attr: 'href' },
};

const fontColorClassesMap = {
  [LINK_BUTTON_THEMES.DARK]: 'text-primary-slate',
  [LINK_BUTTON_THEMES.LIGHT]: 'text-primary-soft-white',
};
const underlineColorClassesMap = {
  [LINK_BUTTON_THEMES.DARK]: 'bg-primary-slate',
  [LINK_BUTTON_THEMES.LIGHT]: 'bg-primary-soft-white',
};

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: (value: string) =>
        Object.values(LINK_BUTTON_THEMES).includes(value),
    },
    href: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const isHover: Ref<boolean> = ref(false);
    const setHoverState = (newHoverState: boolean) => {
      isHover.value = newHoverState;
    };

    // Need to handle href/to attr because :to is always relative
    // and cannot point to external url (which is important to Checkout page AGB link)
    const linkType = computed(() =>
      props.href?.[0] === '/' ? LINK_TYPES.INTERNAL : LINK_TYPES.EXTERNAL
    );

    const componentType = computed(() => attrToLinkTypeMap[linkType.value].component);

    const linkAttr = computed(() => {
      const attrType = attrToLinkTypeMap[linkType.value].attr;
      return {
        [attrType]: props.href,
      };
    });

    const fontColorClasses = fontColorClassesMap[props.theme];
    const underlineColorClasses = isHover.value
      ? 'bg-primary-turquoise'
      : underlineColorClassesMap[props.theme];

    return {
      setHoverState,
      isHover,
      underlineColorClasses,
      fontColorClasses,
      componentType,
      linkAttr,
    };
  },
});
