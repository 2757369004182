
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import ProductList from './ProductList.vue';
import { Product, HeroSliderItem } from '../../types';

export default defineComponent({
  components: {
    ProductList,
  },
  setup() {
    const store = useStore();
    store.dispatch('shopModule/getProducts');
    store.dispatch('shopModule/getHeroSliders');
    store.dispatch('shopModule/getCategories');
    store.dispatch('shopModule/getConfig');

    const products = computed<Product[]>(() =>
      (store.state.shopModule.products as Product[])?.filter(
        (product) => !product.hiddenInListing
      )
    );
    const userPoints = computed<number>(
      () => store.getters['consumerModule/loyaltyPoints']
    );
    const heroSliderItems = computed<HeroSliderItem[]>(
      () => store.state.shopModule.heroSliders
    );

    return {
      products,
      userPoints,
      heroSliderItems,
    };
  },
});
