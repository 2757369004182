import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svgs/icon-notification-signal-error.svg'
import _imports_1 from '@/assets/svgs/icons-20-dropdown-chevron.svg'


const _withScopeId = n => (_pushScopeId("data-v-1a50ee68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["tabindex"]
const _hoisted_2 = {
  key: 0,
  class: "w-6 h-auto absolute right-xxl-4 top-s",
  src: _imports_0,
  alt: "Error notification"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["custom-select-wrapper w-full relative bg-primary-soft-white", { 'sm:pb-24 xl:pb-10': !_ctx.open }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-select outline-none border-none", { 'h-12': !_ctx.open }]),
      tabindex: _ctx.tabindex,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = false))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["custom-select__selected flex justify-between bg-primary-soft-white text-primary-slate cursor-pointer select-none", { open: _ctx.open, hasError: _ctx.error }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
      }, [
        _createTextVNode(_toDisplayString(_ctx.selected || _ctx.placeholder) + " ", 1),
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("img", {
          src: _imports_1,
          alt: "arrow-chevron",
          class: _normalizeClass({ 'mr-3.5': true, 'transform rotate-180': _ctx.open })
        }, null, 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["custom-select__items bg-primary-soft-white mt-1", { hidden: !_ctx.open, 'top-14': _ctx.open, hasError: _ctx.error }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "text-primary-slate cursor-pointer select-none",
            onClick: () => _ctx.onClickOption(option)
          }, _toDisplayString(option), 9, _hoisted_3))
        }), 128))
      ], 2)
    ], 42, _hoisted_1)
  ], 2))
}