
import { computed, defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';

const NOTIFICATION_ACTIVE_TEXT = 'Benachrichtigung aktiv';
const OUT_OF_STOCK_TEXT = 'Benachrichtige mich per E-mail';
const REDEEM_TEXT = 'Einlösen';

export default defineComponent({
  components: {
    Button,
  },
  props: {
    consumerPoints: {
      type: Number,
      required: true,
    },
    productRequiredPoints: {
      type: Number,
      required: true,
    },
    outOfStock: {
      type: Boolean,
      default: false,
    },
    redeemText: {
      type: String,
      default: REDEEM_TEXT,
    },
    showMissingPoints: {
      type: Boolean,
      default: false,
    },
    isDiscount: {
      type: Boolean,
      default: true,
    },
    isLimitedEdition: {
      type: Boolean,
      default: true,
    },
    allowToClickWhenDisabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    variantId: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const hasEnoughPoints = computed(
      () => props.consumerPoints >= props.productRequiredPoints
    );
    const userCanBuyProduct = computed(() => hasEnoughPoints.value);
    const buttonIsDisabled = computed(
      () =>
        props.isActive ||
        (props.allowToClickWhenDisabled ? false : !userCanBuyProduct.value)
    );
    const buttonText = computed(() =>
      props.outOfStock
        ? props.isActive
          ? NOTIFICATION_ACTIVE_TEXT
          : OUT_OF_STOCK_TEXT
        : props.showMissingPoints && !hasEnoughPoints.value
        ? `Dir fehlen ${props.productRequiredPoints - props.consumerPoints} IQOS Points`
        : props.redeemText
    );

    const isProductAvailable = computed(
      () => !props.outOfStock && !props.showMissingPoints
    );

    const showDiscount = computed(
      () => props.isDiscount && isProductAvailable.value
    );

    const showUnitsLeft = computed(
      () => props.isLimitedEdition && isProductAvailable.value
    );

    return {
      buttonText,
      showUnitsLeft,
      showDiscount,
      hasEnoughPoints,
      buttonIsDisabled,
    };
  },
});
