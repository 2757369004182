
import { defineComponent, computed } from 'vue';

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

const NOTIFICATION_ICONS = {
  [NOTIFICATION_TYPES.SUCCESS]: 'icon-notification-signal-success.svg',
  [NOTIFICATION_TYPES.WARNING]: 'icon-notification-signal-warning.svg',
  [NOTIFICATION_TYPES.ERROR]: 'icon-notification-signal-error.svg',
};

export default defineComponent({
  props: {
    type: {
      type: String,
      default: NOTIFICATION_TYPES.WARNING,
      validator: (value: string) =>
        Object.values(NOTIFICATION_TYPES).includes(value),
    },
  },
  setup(props) {
    const iconImageSrc = computed(() =>
      require(`./svg/${NOTIFICATION_ICONS[props.type]}`)
    );
    return {
      iconImageSrc,
    };
  },
});
