
import { defineComponent, ref, computed, PropType } from 'vue';
import { useStore } from 'vuex';
import { useFeatures } from '@/composables/features';

import { Notification } from '@/modules/shop/types';

import Badge from '@/components/Badge/Badge.vue';
import { getProductDetailUrl } from '@/modules/shop/router';
import { ProductTypesEnum, Variant, Product } from '@/modules/shop/types';
import { PRODUCT_TYPES } from '@/modules/shop/types';
import {
  productClickDefault,
  pushDataLayer,
  pointsInEuro,
  brandGTM,
} from '@/utils/GTMCustomDataLayers/eCommerce';

import Icon from '@/components/Icon/Icon.vue';
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
import Link from '@/components/Link/Link.vue';
import RedeemButton from '@/components/RedeemButton/RedeemButton.vue';
import Voucher from '@/components/Voucher/Voucher.vue';

import useTailwind from '@/use/useTailwind';

const priorityTierMap = {
  silver: 1,
  gold: 2,
  platinum: 3,
};

export default defineComponent({
  components: {
    ColorPicker,
    Link,
    RedeemButton,
    Voucher,
    Badge,
    Icon,
  },
  props: {
    variants: {
      type: Object as () => Variant[],
      required: true,
    },
    notifications: {
      type: Object as () => Notification[],
      required: true,
    },
    requiredTier: {
      type: String as () => Product['requiredTier'],
      default: 'silver',
    },
    currentTier: {
      type: String as () => Product['requiredTier'],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    requiredPointsBefore: {
      type: Number,
      default: null,
    },
    price: {
      type: Number,
      default: null,
    },
    type: {
      type: String as PropType<ProductTypesEnum>,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    userPoints: {
      type: Number,
      default: 0,
    },
    productId: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    externalURL: {
      type: String || null,
      default: null,
    },
    isLimitedEdition: {
      type: Boolean,
      default: false,
    },
    displayRef: {
      type: String || null,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    notes: {
      type: String as () => Product['notes'],
      default: null,
    },
  },
  setup(props) {
    const { getFeature, isLoading: areFeatureFlagsLoaded } = useFeatures();
    const { theme } = useTailwind();
    const currentVariant = ref(props.variants[0]);
    const notificationPopup = ref(false);
    const isAvailable = ref(true);
    const activateNotification = ref(false);
    const notificationStatus = computed((): boolean => {
      // calculate notification only when outOfStock is true
      return (
        currentVariant.value.outOfStock &&
        (activateNotification.value ||
          props.notifications.some(
            (n: Notification) =>
              n.productId == props.productId &&
              n.variantId == currentVariant.value.variantId
          ))
      );
    });

    const isNotesOnProductEnabled = computed<boolean>(
      () => getFeature('IQOS-1609-product-notes').enabled
    );

    const selectVariant = (index) =>
      (currentVariant.value = props.variants[index]);

    const isItgProduct = computed(() =>
      [PRODUCT_TYPES.ITG].includes(props.type)
    );

    const isItgAdditionalProduct = computed(() =>
      [PRODUCT_TYPES.ITG_ADDITIONAL].includes(props.type)
    );

    const isIqosDeviceProduct = computed(() =>
      [PRODUCT_TYPES.IQOS_DEVICE].includes(props.type)
    );

    const isArvatoProduct = computed(() =>
      [PRODUCT_TYPES.ARVATO].includes(props.type)
    );

    const productLabelBadgeBgColor = computed(() => {
      if (currentVariant.value.outOfStock) return '#ffff';
      if (props.isLimitedEdition) return theme.colors['secondary-amber'];
      return props.labelColor || theme.colors['primary-turquoise'];
    });

    const productRedeemText = computed(() =>
      props.requiredPointsBefore
        ? 'Zum Angebot'
        : props.isLimitedEdition
        ? `Nur noch ${currentVariant.value.unitsLeft || ''} Stück verfüghbar`
        : [PRODUCT_TYPES.VOUCHER].includes(props.type)
        ? 'Zum Voucher'
        : props.type === PRODUCT_TYPES.ITG_ADDITIONAL
        ? 'So funktioniert\'s'
        : 'Zum Produkt'
    );

    const redeemURL = computed(() =>
      isIqosDeviceProduct.value
        ? props.externalURL
        : currentVariant.value.variantURL
    );

    const store = useStore();
    const setUserInteraction = () => {
      const productGTM = {
        ...productClickDefault,
        name: props.name,
        id: props.productId,
        price: pointsInEuro(props.price),
        category: props.category,
        variant: currentVariant.value.name,
        position: props.index,
        brand: brandGTM(props.type),
      };
      store.dispatch('shopModule/setUserInteraction', props.displayRef);
      pushDataLayer('productClick', {
        list: props.displayRef,
        productList: [productGTM],
      });
    };
    const handleClick = (val) => {
      notificationPopup.value = val;
      setUserInteraction();
    };

    const requiredTierConfig = computed(() => {
      const tierConfig = {
        silver: {
          priority: priorityTierMap.silver,
          background: '',
          paddings: 'px-0',
          button: '',
          labelText: '',
          labelColor: '',
          textColor: '',
          buttonTheme: 'dark',
        },
        gold: {
          priority: priorityTierMap.gold,
          background:
            getTierPriority(props.currentTier) >= priorityTierMap.gold
              ? null
              : 'bg-secondary-yellow',
          paddings: 'px-3.5 sm:px-m lg:px-l',
          button: 'bg-transparent',
          labelText: 'Gold Produkt',
          labelColor: theme.colors['secondary-yellow'],
          textColor: '',
          buttonTheme: 'dark',
        },
        platinum: {
          priority: priorityTierMap.platinum,
          background:
            getTierPriority(props.currentTier) >= priorityTierMap.platinum
              ? null
              : 'bg-secondary-platinum',
          paddings: 'px-3.5 sm:px-m lg:px-l',
          button: 'bg-transparent',
          labelText: 'Platin Produkt',
          labelColor: theme.colors['tints-secondary-platinum'],
          textColor:
            getTierPriority(props.currentTier) >= priorityTierMap.platinum
              ? null
              : 'text-primary-soft-white',
          buttonTheme:
            getTierPriority(props.currentTier) >= priorityTierMap.platinum
              ? 'dark'
              : 'light',
        },
      };

      return tierConfig[props.requiredTier];
    });

    const getTierPriority = (tier) =>
      priorityTierMap[tier] || priorityTierMap.silver;
    const isTierLocked = computed(
      () =>
        getTierPriority(props.requiredTier) > getTierPriority(props.currentTier)
    );

    return {
      areFeatureFlagsLoaded,
      activateNotification,
      currentVariant,
      getProductDetailUrl,
      handleClick,
      isAvailable,
      isIqosDeviceProduct,
      isItgAdditionalProduct,
      isItgProduct,
      isArvatoProduct,
      isTierLocked,
      notificationPopup,
      notificationStatus,
      productLabelBadgeBgColor,
      productRedeemText,
      redeemURL,
      requiredTierConfig,
      selectVariant,
      setUserInteraction,
      isNotesOnProductEnabled,
    };
  },
});
