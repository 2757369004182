
import { defineComponent, ref, computed } from 'vue';
import useBreakpoints from '@/use/useBreakpoints';
import { Product } from '@/modules/shop/types';

export default defineComponent({
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
  },
  setup(props) {
    const shouldReadMore = ref(false);
    const breakpoints = useBreakpoints();
    const toggleReadMore = (): void => {
      shouldReadMore.value = !shouldReadMore.value;
    };

    const showReadMore = computed(() =>  breakpoints.isXl()
        ? props.product.description.length >= 150
        : breakpoints.isLg()
        ? props.product.description.length >= 136
        : breakpoints.isMd()
        ? props.product.description.length >= 160
        : props.product.description.length >= 110
    )

    const getProductDescription = () => {
      const characterAmount = showReadMore.value && breakpoints.isXl()
        ? 150
        : breakpoints.isLg()
        ? 136
        : breakpoints.isMd()
        ? 160
        : 110; // isSm()
      return `${props.product.description.substring(0, characterAmount)}...`;
    };

    return {
      showReadMore,
      shouldReadMore,
      toggleReadMore,
      getProductDescription,
    };
  },
});
