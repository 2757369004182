
import { defineComponent, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { Product, Variant } from '@/modules/shop/types';
import { DeprecatedAcademyClass } from '@/modules/academy/types';
import { routeNames } from '@/modules/shop/router';

import { Breadcrumb } from './Breadcrumbs.types';
import Breadcrumbs from './Breadcrumbs.vue';

export default defineComponent({
  components: {
    Breadcrumbs,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isCheckoutPage = computed(
      () =>
        route.name === routeNames.CHECKOUT ||
        route.name === routeNames.ORDER_CONFIRMATION
    );

    const currentProduct: ComputedRef<Product> = computed(
      () => store.state.shopModule.currentProduct
    );

    const currentVariant: ComputedRef<Variant> = computed(
      () => store.state.shopModule.currentVariant
    );

    const currentClass: ComputedRef<DeprecatedAcademyClass> = computed(
      () => store.getters['academyModule/currentClass']
    );

    const breadcrumbs: ComputedRef<Breadcrumb[]> = computed(
      () => route.meta.breadcrumbs
    );
    /**
     * @name breadcrumbLastItem
     * @description Display the last item on breadcrumbs
     * Usually this is the name we gave in the route.meta.routeBreadcrumbName but
     * If we are in a route with an dynamic ID
     * we need to display the dynamic item
     * ** PRODUCT_DETAIL : product name,
     * ** ACADEMY_CLASS : class name
     * @returns {String}
     */
    const breadcrumbLastItem = computed(() => {
      const routeHandlerMap = {
        PRODUCT_DETAIL: currentProduct.value && currentProduct.value.name,
        ACADEMY_CLASS: currentClass.value && currentClass.value.title,
        default: route.meta.routeBreadcrumbName,
      };
      return routeHandlerMap[route.name] || routeHandlerMap.default;
    });

    const userPoints = computed(
      () => store.getters['consumerModule/loyaltyPoints']
    );

    // Trigger the getConsumerLoyalty action after being authenticated
    store.watch(
      (_, getters) => getters['authModule/isUserAuthenticated'],
      (isUserAuthenticated) =>
        isUserAuthenticated && store.dispatch('consumerModule/getConsumerLoyalty')
        // isUserAuthenticated && !userPoints.value && store.dispatch('consumerModule/getConsumerLoyalty')
    );

    return {
      breadcrumbLastItem,
      breadcrumbs,
      currentProduct,
      currentVariant,
      isCheckoutPage,
      userPoints,
      currentClass,
    };
  },
});
