
import { defineComponent, computed, ComputedRef, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import {
  Product,
  ProductCheckout,
  Variant,
  PRODUCT_TYPES,
} from '@/modules/shop/types';
import {
  productFieldDefault,
  pushDataLayer,
  pointsInEuro,
  brandGTM,
} from '@/utils/GTMCustomDataLayers/eCommerce';

import { routeNames } from '@/modules/shop/router';

import { Consumer } from '@/modules/consumer/types';

import Checkout from './Checkout.vue';

export default defineComponent({
  components: {
    Checkout,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const productId = route.params.productId as string;
    const variantId = route.params.variantId as string;

    store.dispatch('consumerModule/getConsumer');
    store.dispatch('shopModule/resetProductOrder');

    const consumer: ComputedRef<Consumer> = computed(
      () => store.state.consumerModule.consumer
    );

    const loyaltyPoints: ComputedRef<number> = computed(
      () => store.getters['consumerModule/loyaltyPoints']
    );

    const product: ComputedRef<Product> = computed(
      () => store.state.shopModule.currentProduct
    );

    const additionalProduct: ComputedRef<Product> = computed(() => {
      const mainProductType = product.value.type;
      const relatedProductTypeMap = {
        [PRODUCT_TYPES.ITG]: PRODUCT_TYPES.ITG_ADDITIONAL,
        [PRODUCT_TYPES.ARVATO]: PRODUCT_TYPES.ARVATO_ADDITIONAL,
      };
      return relatedProductTypeMap[mainProductType] && store.state.shopModule.products.find(
        (product) =>
          product.type === relatedProductTypeMap[mainProductType] &&
          product.name.indexOf('IQOS Sammelbeutel') === 0
      );
  });

    const variant: ComputedRef<Variant> = computed(
      () => store.state.shopModule.currentVariant
    );

    store.dispatch('shopModule/getProduct', { productId, variantId });

    const goToOrderSuccessPage = () =>
      router.push({
        name: routeNames.ORDER_CONFIRMATION,
      });

    const networkError: ComputedRef<string> = computed(
      () => store.state.shopModule.orderProductError
    );

    const orderAction = (formData) => {
      const productCheckout: ProductCheckout =
        product.value.type == 'voucher'
          ? { variantId }
          : {
              variantId,
              shippingAddress: {
                city: formData.city,
                firstName: formData.firstName,
                lastName: formData.lastName,
                street: formData.street,
                ...(formData.extraAdress && { company: formData.extraAdress }),
                streetNumber: formData.houseNumber,
                zipCode: formData.postalCode,
              },
              additionalProduct: {
                additionalProductId: formData.additionalProductId,
                additionalVariantId: formData.additionalVariantId,
                additionalAmount: formData.additionalAmount,
              },
            };
      return store.dispatch('shopModule/orderProduct', {
        productId: product.value.productId,
        productCheckout,
      });
    };

    watch(
      () => store.state.shopModule.order,
      (currentOrder, previousOrder) => {
        currentOrder && !previousOrder && goToOrderSuccessPage();
      }
    );
    watch(product, (value) => {
      const productGTM = {
        ...productFieldDefault,
        name: value.name,
        id: value.productId,
        price: pointsInEuro(value.requiredPoints),
        category: value.category,
        variant: value.variants[0].name,
        brand: brandGTM(value.type),
        quantity: 1,
      };
      pushDataLayer('productCheckout', { productList: [productGTM] });
    });

    return {
      product,
      additionalProduct,
      variant,
      orderAction,
      consumer,
      loyaltyPoints,
      networkError,
    };
  },
});
