
import {
  ComputedRef,
  Ref,
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import { CLUB_LOYALTY_URL } from '@/modules/auth/constants';
import {
  productFieldDefault,
  actionFieldDefault,
  pushDataLayer,
  pointsInEuro,
} from '@/utils/GTMCustomDataLayers/eCommerce';

import {
  Product,
  Variant,
  PRODUCT_TYPES,
  Order,
  CustomerInfo,
} from '@/modules/shop/types';
import {
  ProductFieldObject,
  ActionField,
} from '@/utils/GTMCustomDataLayers/types';
import { HelpTopics } from '@/components/ItemStack/ItemStack.types';
import Button, { BUTTON_THEMES } from '@/components/Button/Button.vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';
import InlineNotification, {
  NOTIFICATION_TYPES,
} from '@/components/InlineNotification/InlineNotification.vue';
import ItemStack from '@/components/ItemStack/ItemStack.vue';
import Voucher from '@/components/Voucher/Voucher.vue';
import AdvertisingTiles from '../../components/AdvertisingTiles/AdvertisingTiles.vue';

import { useGtag } from 'vue-gtag-next';

import { teasers } from '@/modules/shop/helpers/teasers';
import CheckoutHeader from '@/modules/shop/components/CheckoutHeader/CheckoutHeader.vue';
import CheckoutSteps from '@/modules/shop/components/CheckoutSteps/CheckoutSteps.vue';
import CheckoutCard from '@/modules/shop/components/CheckoutCard/CheckoutCard.vue';
import { Consumer } from '@/modules/consumer/types';
import { routeNames } from '@/router/index';
type CodeNotification = null | {
  message: string;
  type: string;
};

const getTodayDate = (): string => {
  const orderDateRaw = new Date();

  const day = orderDateRaw.getDate().toString().padStart(2, '0');
  const month = (orderDateRaw.getMonth() + 1).toString().padStart(2, '0');
  const year = orderDateRaw.getFullYear();

  return `${day}.${month}.${year}`;
};

const teasersData = teasers;

export default defineComponent({
  components: {
    Button,
    CheckoutCard,
    CheckoutHeader,
    CheckoutSteps,
    Icon,
    InlineNotification,
    ItemStack,
    Voucher,
    AdvertisingTiles,
  },
  props: {
    product: {
      type: Object as () => Product,
      required: true,
    },
    additionalProduct: {
      type: Object as () => Product,
      required: true,
    },
    variant: {
      type: Object as () => Variant,
      required: true,
    },
    attributesWithIcons: {
      type: Object as () => { text: string; icon: string }[],
      required: true,
    },
    loyaltyPoints: {
      type: Number,
      required: true,
    },
    order: {
      type: Object as () => Order,
      required: true,
    },
    shippingAddress: {
      type: Object as () => CustomerInfo,
      required: true,
    },
    consumer: {
      type: Object as () => Consumer,
      required: true,
    },
  },
  setup(props) {
    const isShippableProduct: ComputedRef<boolean> = computed(() =>
      [PRODUCT_TYPES.ITG, PRODUCT_TYPES.ARVATO].includes(props.product.type)
    );
    const { event } = useGtag();
    const orderDate = getTodayDate();

    const codeNotification: Ref<CodeNotification> = ref(null);
    const resetCodeNotification = (): void => (codeNotification.value = null);

    const copyCodeToClipboard = (voucherCode) => {
      const successCallback = () => {
        codeNotification.value = {
          message: 'Code erfolgreich kopiert.',
          type: NOTIFICATION_TYPES.SUCCESS,
        };
      };

      const errorCallback = () => {
        codeNotification.value = {
          message: `
            Etwas ist schief gelaufen.
            Bitte kopieren Sie den Code manuell.
          `,
          type: NOTIFICATION_TYPES.ERROR,
        };
      };

      navigator.clipboard
        .writeText(voucherCode)
        .then(successCallback, errorCallback);

      setTimeout(resetCodeNotification, 3000);
    };

    const helpTopics: HelpTopics[] = [
      {
        title: 'FAQs',
        icon: 'info',
        text: 'Antworten auf häufige Fragen',
        href: '/rewardshop#product-list__faq',
      },
      {
        title: 'Hotline',
        icon: 'phone',
        text: 'Einen IQOS Experten anrufen',
        href: 'tel:0800-1114-767',
      },
      {
        title: 'Community',
        icon: 'coach',
        text: 'Tipps & Tricks auf Facebook',
        href: 'https://www.facebook.com/iqos.support.de/',
      },
    ];

    const productGTMPurchase = computed(
      (): ProductFieldObject => ({
        ...productFieldDefault,
        name: props.product.name,
        id: props.product.productId,
        price: pointsInEuro(props.product.requiredPoints),
        category: props.product.category,
        variant: props.product.variants[0].name,
        quantity: 1,
      })
    );
    const actionFieldGTM = computed(
      (): ActionField => ({
        ...actionFieldDefault,
        id: props.order.orderId,
        revenue: pointsInEuro(props.product.requiredPoints),
      })
    );
    onMounted(() => {
      pushDataLayer('productPurchase', {
        productList: [productGTMPurchase.value],
        actionFieldPurchase: actionFieldGTM.value,
      });
      event('Product_Purchase', {
        event_category: 'ecommerce',
        event_label: `${props.product.name} | ${props.variant.name}`,
        value: props.product.requiredPoints,
      });
    });

    return {
      BUTTON_THEMES,
      CLUB_LOYALTY_URL,
      ICON_THEMES,
      codeNotification,
      copyCodeToClipboard,
      helpTopics,
      isShippableProduct,
      orderDate,
      surveyLink: `/${routeNames.SURVEY}`,
      teasersData,
    };
  },
});
