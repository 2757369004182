
import { defineComponent, computed, ref } from 'vue';
import { Category } from '../../types';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const allCategories = computed<Category[]>(
      () => [
        { name: 'Alle Produkte', description: '' },
        ...store.state.shopModule.categories,
      ],
    );
    const selected = ref(0);

    const selectCategory = (index: number) => {
      // Clicking on all products or twice on the same tab remove product filters.
      // Only fetch products when the filter has changed.
      const S = selected.value;
      selected.value = (index === 0 || index === selected.value) ? 0 : index;
      if (index !== 0 && S !== index) store.dispatch('shopModule/getProductsByCategory', ({ categoryName: allCategories.value[index].name }));
      else store.dispatch('shopModule/getProducts');
    }

    return {
      selected,
      allCategories,
      selectCategory,
    };
  },
});
