import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "advertising-tiles-slider overflow-hidden pb-16 sm:pb-64 md:pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvertisingTile = _resolveComponent("AdvertisingTile")!
  const _component_VueperSlide = _resolveComponent("VueperSlide")!
  const _component_VueperSlides = _resolveComponent("VueperSlides")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueperSlides, {
      arrows: false,
      touchable: "",
      fixedHeight: _ctx.sliderHeight,
      draggingDistance: 30
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tiles, (classItem) => {
          return (_openBlock(), _createBlock(_component_VueperSlide, {
            key: classItem.id
          }, {
            content: _withCtx(() => [
              (_openBlock(), _createBlock(_component_AdvertisingTile, {
                key: classItem.id,
                title: classItem.title,
                image: classItem.image,
                points: classItem.points,
                category: classItem.category,
                subtitle: classItem.subtitle,
                info: classItem.info,
                color: classItem.color,
                url: classItem.url
              }, null, 8, ["title", "image", "points", "category", "subtitle", "info", "color", "url"]))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["fixedHeight"])
  ]))
}