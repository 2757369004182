<template>
  <h1 class="checkout-header
      flex items-center justify-center
      py-xxl-4 px-xxl-2
      mb-xl
      bg-primary-slate
      text-primary-soft-white
      text-center text-xl md:text-2xl xl:text-fs-28-lh-40-ls-0
      "
    >
      <slot />
    </h1>
</template>

<script>
export default {};
</script>

<style>
.checkout-header {
  min-height: 136px;
}
</style>