import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductList = _resolveComponent("ProductList")!

  return (_openBlock(), _createBlock(_component_ProductList, {
    products: _ctx.products,
    heroSliderItems: _ctx.heroSliderItems,
    userPoints: _ctx.userPoints
  }, null, 8, ["products", "heroSliderItems", "userPoints"]))
}