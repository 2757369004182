
import { defineComponent } from 'vue';

import { scrollIntoView } from '@/use/useScroll';

import Button from '@/components/Button/Button.vue';
import Icon, { ICON_THEMES } from '@/components/Icon/Icon.vue';

const CLICK_EVENT = 'click';

export default defineComponent({
  components: { Button, Icon },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
  },
  emits: [CLICK_EVENT],
  setup(_, { emit }) {
    return {
      handleClick: () => emit(CLICK_EVENT),
      scrollIntoView,
      ICON_THEMES,
    };
  },
});
