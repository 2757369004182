import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueperSlides = _resolveComponent("VueperSlides")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["carousel", {
      'carousel--no-left-arrow': _ctx.hideLeftArrow,
      'carousel--no-right-arrow': _ctx.hideRightArrow,
    }])
  }, [
    _createVNode(_component_VueperSlides, _mergeProps({
      ref: "vueperRef",
      class: ["no-shadow", { 'carousel__custom-arrow-position': _ctx.customArrowPosition }],
      breakpoints: _ctx.computedBreakpointsConfig,
      draggingDistance: 70,
      infinite: false,
      autoplay: _ctx.autoplay,
      duration: _ctx.duration,
      preventYScroll: _ctx.preventYScroll,
      slideImageInside: "",
      transitionSpeed: "300"
    }, _ctx.$attrs), _createSlots({
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 2
    }, [
      (!_ctx.hideLeftArrow)
        ? {
            name: "arrow-left",
            fn: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.leftArrowIcon,
                alt: "arrow left",
                class: "w-12 h-12"
              }, null, 8, _hoisted_1)
            ]),
            key: "0"
          }
        : undefined,
      (!_ctx.hideRightArrow)
        ? {
            name: "arrow-right",
            fn: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.rightArrowIcon,
                alt: "arrow left",
                class: "w-12 h-12"
              }, null, 8, _hoisted_2)
            ]),
            key: "1"
          }
        : undefined
    ]), 1040, ["breakpoints", "class", "autoplay", "duration", "preventYScroll"])
  ], 2))
}