
import { defineComponent, computed, ComputedRef, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { routeNames } from '@/modules/shop/router';
import { Product, Variant } from '@/modules/shop/types';
import useCarousel from '@/use/useCarousel';
import {
  impressionFieldDefault,
  productFieldDefault,
  pushDataLayer,
  pointsInEuro,
  brandGTM,
} from '@/utils/GTMCustomDataLayers/eCommerce';
import { ImpressionFieldObject } from '@/utils/GTMCustomDataLayers/types';

import ProductDetails from './ProductDetails.vue';
import { round } from 'lodash';

const visibleSlidesPerBreakpoint = {
  xl: 3.05,
  lg: 3.05,
  md: 2.1,
  sm: 1.05,
};

export default defineComponent({
  components: {
    ProductDetails,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    store.dispatch('shopModule/getConfig');

    const productId = computed(() => route.params.id);
    const variantId = computed(() => route.params.variantId);

    const product: ComputedRef<Product> = computed(
      () => store.state.shopModule.currentProduct
    );
    const products: ComputedRef<Product[]> = computed(
      () => store.state.shopModule.products
    );
    const userPoints: ComputedRef<number> = computed(
      () => store.getters['consumerModule/loyaltyPoints']
    );

    const totalItems: ComputedRef<number> = computed(
      () => products.value.length
    );
    const { visibleSlides } = useCarousel({
      visibleSlidesPerBreakpoint,
      totalItems,
    });
    const getProduct = async () => {
      await store.dispatch('shopModule/getProduct', {
        productId: productId.value,
        variantId: variantId.value,
      });

      // Retrive just the visible carousel product
      const productsCarouselVisible = products.value.slice(
        0,
        round(visibleSlides.value)
      );
      // Get variant object based on variant selected
      const currentVariantObject = computed(() =>
        product.value.variants.find((v) => v.variantId === variantId.value)
      );
      // Get variant availability
      const productAvailability = computed(() =>
        currentVariantObject.value.outOfStock ? 'out of stock' : 'in stock'
      );
      const productImpressionGTM = productsCarouselVisible.map(
        (product, index): ImpressionFieldObject => ({
          ...impressionFieldDefault,
          name: product.name,
          id: product.productId,
          price: pointsInEuro(product.requiredPoints),
          category: product.category,
          variant: product.variants[0].name,
          list: 'Carousel',
          position: index,
          brand: brandGTM(product.type),
        })
      );

      const currentProductGTMField = {
        ...productFieldDefault,
        name: product.value.name,
        id: product.value.productId,
        price: product.value.requiredPoints,
        category: product.value.category,
        variant: currentVariantObject.value.name,
        availability: productAvailability.value,
      };

      pushDataLayer('productDetail', {
        productImpressionList: productImpressionGTM,
        list: store.state.shopModule.userInteractionToProductDetail,
        productList: [currentProductGTMField],
      });
    };

    const getProducts = async () => {
      await store.dispatch('shopModule/getProducts');
    };
    getProducts();
    watch(
      productId,
      async (nextproductId, prevproductId) => {
        if (nextproductId && nextproductId !== prevproductId) {
          await getProduct();
        }
      },
      { immediate: true }
    );

    const handleVariantSelected = (variant: Variant): void => {
      router.push({
        name: routeNames.PRODUCT_DETAIL,
        params: {
          productId: productId.value,
          variantId: variant.variantId,
        },
      });
    };

    const checkoutUrl: ComputedRef<string> = computed(() => {
      const { currentProduct } = store.state.shopModule;
      return `/order/address/${currentProduct.productId}/${variantId.value}`;
    });

    const tierLevel = computed(() =>
      store.getters['consumerModule/statusLevel']?.toLowerCase()
    );

    return {
      checkoutUrl,
      handleVariantSelected,
      product,
      products,
      userPoints,
      variantId,
      tierLevel,
    };
  },
});
