
import { defineComponent, PropType } from 'vue';
import { AdvertisingTileType } from '@/modules/shop/types';

import AdvertisingTile from './AdvertisingTile.vue';
import AdvertisingTilesSlider from './AdvertisingTilesSlider.vue';

export default defineComponent({
  components: {
    AdvertisingTile,
    AdvertisingTilesSlider,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    tiles: {
      type: Array as PropType<AdvertisingTileType[]>,
      required: true,
    },
  },
});
