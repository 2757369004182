export default [
  {
    question:
      'Was sind Statuspunkte und was sind IQOS Points? Weshalb unterscheiden sich diese?',
    answer: `
    Die Höhe der gesammelten Statuspunkte definieren Deine Vorteile im IQOS Club: eine Übersicht findest Du unter "Vorteile erleben". Statuspunkte sammelst Du mit jeder Aktivität. Die Höhe der Statuspunkte variiert je nach Aktivität.
    <br/>
    Zusätzlich kannst du IQOS Points sammeln, welche Du gegen attraktive Prämien oder IQOS Produkte eintauschen kannst. IQOS Points erhältst Du bei erfolgreicher Empfehlung eines Freundes oder durch den Abschluss einer Challenge in der IQOS Club Academy.
    <br/>
    Für jeden gesammelten IQOS Points erhältst Du auch in gleicher Anzahl Statuspunkte.
    <br/>
    Wenn Du IQOS Points gegen Prämien einlöst, verringert sich Deine Anzahl an IQOS Points, jedoch hat dies keine Auswirkung auf Deine derzeitigen Statuspunkte und Vorteile im Club.
    <br/>
    Daher unterscheiden sich auch beide Punktestände.
  `,
  },
  {
    question: 'Wie kann ich IQOS Points sammeln?',
    answer: `
    IQOS Points können über verschiedenen Aktivitäten gesammelt werden: eine Übersicht findest Du auf der Seite "Punkte sammeln".
    <br/>
    Bitte beachte, dass IQOS Points nicht gleichzusetzen sind mit Statuspunkten.
    `,
  },
  {
    question:
      'Was kann ich mit IQOS Points machen und wie viel sind diese wert?',
    answer: `
    IQOS Points können im Prämienshop gegen attraktive Prämien unserer Partner eingetauscht werden. Zusätzlich kannst Du Deine IQOS Points für IQOS Geräte und Accessoires (ausgenommen Tabaksticks) in unserem Onlineshop auf iqos.com einlösen.
    <br/>
    — 10 IQOS Points entsprechen 1 EUR.                                                                       
    <br/>
    Achte darauf, dass Du bei Lieferung Deiner Sendung zuhause bist, da für den Empfang eine Ausweisprüfung notwendig ist.
    <br/>
    Bitte beachte, dass das Einlösen Deiner IQOS Points gegen Tabaksticks ausgenommen ist.
    `,
  },
  {
    question: 'Wie kann ich meine IQOS Points einlösen?',
    answer: `
    Deine gesammelten IQOS Points kannst Du im Prämienshop gegen exklusive Partnerprämien eintauschen oder auf iqos.com für IQOS Geräte und Zubehör (ausgenommen Tabaksticks) einlösen. In unserem Onlineshop auf iqos.com einfach das gewünschte IQOS Gerät oder Zubehör in den Warenkorb legen und im Schritt Zahlung die Höhe der einzulösenden Punkte eingeben.
    `,
  },
  {
    question: 'Gegen was kann ich meine IQOS Points einlösen?',
    answer: `
    Du kannst Deine gesammelten IQOS Points gegen jede verfügbare Prämie im Prämienshop oder IQOS Gerät und Zubehör auf iqos.com einlösen.
    <br/>
    Achte darauf, dass Du bei Lieferung Deiner Sendung zuhause bist, da für den Empfang eine Ausweisprüfung notwendig ist.
    <br/>
    Bitte beachte, dass das Einlösen Deiner IQOS Points gegen Tabaksticks ausgenommen ist.
    `,
  },
  {
    question:
      'Kann ich auch eine Prämie bestellen, für die meine IQOS Points nicht ausreichen?',
    answer: `
    Du kannst IQOS Geräte und Zubehör (Tabaksticks ausgenommen) über iqos.com bestellen, auch wenn Du nicht genügend IQOS Points zur Verfügung hast oder nur einen Teil Deiner Punkte einlösen möchtest, denn den Differenzbetrag kannst Du über eine Zuzahlung begleichen.
    <br/>
    Für unsere Partnerprämien ist dies nicht möglich.
    `,
  },
  {
    question: 'Kann ich den Lieferstatus meiner Prämienbestellung verfolgen?',
    answer: `
    Du kannst Deine Bestellung in Deinem Kundenkonto einsehen. Sobald Deine Prämie verschickt wurde, erhältst Du eine E-mail mit Versandbestätigung und Sendungsverfolgung.
    `,
  },
];
