import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "inline-notification__slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-notification", [
      'flex flex-row items-center space-x-l',
      'bg-background-hard-white',
      'text-xs',
      'py-l pl-l pr-xxl',
    ]])
  }, [
    _createElementVNode("img", {
      class: "w-6",
      src: _ctx.iconImageSrc,
      alt: _ctx.type
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}