import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "color-picker" }
const _hoisted_2 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center space-x-3.5 p-xxs", {'space-x-s': _ctx.xl}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color, index) => {
        return (_openBlock(), _createElementBlock("button", {
          id: `${_ctx.id}_${index}`,
          key: index,
          class: _normalizeClass([{
          'color-picker__bullet--xl': _ctx.xl,
          'color-picker__bullet--active': _ctx.currentColorIndex === index}, "color-picker__bullet"]),
          style: _normalizeStyle(`background-color: ${color}`),
          onClick: ($event: any) => (_ctx.changeColor(index))
        }, null, 14, _hoisted_2))
      }), 128))
    ], 2)
  ]))
}