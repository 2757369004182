import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "banner-with-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, {
      class: "h-16",
      type: "info",
      theme: _ctx.ICON_THEMES.LIGHT_TURQUOISE
    }, null, 8, ["theme"]),
    _createElementVNode("div", {
      class: _normalizeClass(["banner-with-button__text", [
        'text-center',
        'text-fs-14-lh-20-ls-0',
        'md:text-fs-16-lh-24-ls-0',
        'xl:text-fs-16-lh-24-ls-0',
      ]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_Button, {
      outline: "",
      theme: "light",
      class: "banner-with-button__button",
      onClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}