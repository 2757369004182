
import {
  ComputedRef,
  PropType,
  computed,
  defineComponent,
  onMounted,
  ref,
} from 'vue';
import { useStore } from 'vuex';

import { useFeatures } from '@/composables/features';

import useTailwind from '@/use/useTailwind';

import Badge from '@/components/Badge/Badge.vue';
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue';
import Icon from '@/components/Icon/Icon.vue';
import Button from '@/components/Button/Button.vue';
import RedeemButton from '@/components/RedeemButton/RedeemButton.vue';
import Voucher from '@/components/Voucher/Voucher.vue';
import { teasers } from '@/modules/shop/helpers/teasers';

import { useGtag } from 'vue-gtag-next';
import {
  productFieldDefault,
  pushDataLayer,
  pointsInEuro,
  brandGTM,
} from '@/utils/GTMCustomDataLayers/eCommerce';

import { Notification } from '@/modules/shop/types';

import AdvertisingTiles from '../../components/AdvertisingTiles/AdvertisingTiles.vue';
import ImageSlider from '../../components/ImageSlider.vue';
import ImageSliderOverlay from '../../components/ImageSliderOverlay.vue';
import ProductDescription from '../../components/ProductDescription.vue';
import ProductListingCarousel from '../../components/ProductListingCarousel/ProductListingCarousel.vue';

import { Product, PRODUCT_TYPES, Variant } from '@/modules/shop/types';

const EVENTS = {
  SELECT_VARIANT: 'selectVariant',
};

export default defineComponent({
  components: {
    AdvertisingTiles,
    Badge,
    Button,
    ColorPicker,
    Icon,
    ImageSlider,
    ImageSliderOverlay,
    ProductDescription,
    ProductListingCarousel,
    RedeemButton,
    Voucher,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    currentTier: {
      type: Object as () => Product['requiredTier'],
      required: true,
    },
    products: {
      type: Array as PropType<Product[]> | null,
      default: null,
    },
    checkoutUrl: {
      type: String,
      required: true,
    },
    userPoints: {
      type: Number,
      required: true,
    },
    variantId: {
      type: String,
      required: true,
    },
  },
  emits: [EVENTS.SELECT_VARIANT],
  setup(props, { emit }) {
    const { getFeature, isLoading: areFeatureFlagsLoaded } = useFeatures();
    const { theme } = useTailwind();
    const currentImageOverlay = ref(0);
    const isOpenImageOverlay = ref(false);
    const { event } = useGtag();
    const notificationPopup = ref(false);
    const activateNotification = ref(false);
    const notifications: ComputedRef<Notification[]> = computed(
      () => store.state.shopModule.notifications
    );

    const shouldDisplayNotes = computed<boolean>(
      () =>
        areFeatureFlagsLoaded &&
        getFeature('IQOS-1609-product-notes').enabled &&
        !!props.product.notes
    );

    const notificationStatus = computed((): boolean => {
      // calculate notification only when outOfStock is true
      return (
        currentVariant.value.outOfStock &&
        (activateNotification.value ||
          notifications.value.some(
            (n: Notification) =>
              n.productId == props.product.productId &&
              n.variantId == currentVariant.value.variantId
          ))
      );
    });

    const store = useStore();

    const currentVariant: ComputedRef<Variant> = computed(
      () =>
        props.product.variants.find((v) => v.variantId === props.variantId) ||
        props.product.variants[0]
    );

    const mainProductImages = computed(() =>
      currentVariant.value.images.map((image, index) => ({
        name: index,
        url: image,
      }))
    );

    const selectVariant = (newVariantIndex) => {
      emit(EVENTS.SELECT_VARIANT, props.product.variants[newVariantIndex]);
    };
    const openImageSliderOverlay = (activeSlideNumber) => {
      currentImageOverlay.value = activeSlideNumber + 1;
      isOpenImageOverlay.value = true;
    };

    const isItgProduct = computed(() =>
      [PRODUCT_TYPES.ITG].includes(props.product.type)
    );

    const isItgAdditionalProduct = computed(() =>
      [PRODUCT_TYPES.ITG_ADDITIONAL].includes(props.product.type)
    );

    const isArvatoProduct = computed(() =>
      [PRODUCT_TYPES.ARVATO].includes(props.product.type)
    );

    const productLabelBadgeBgColor = computed(() => {
      if (isTierLocked.value) return requiredTierConfig.value.labelColor;
      if (currentVariant.value.outOfStock) return '#ffff';
      if (props.product.limitedEdition) return '#e27d34';
      return props.product.labelColor || '#00d1d2';
    });

    const productLabelBadgeText = computed(() => {
      if (currentVariant.value.outOfStock) return 'Aktuell nicht verfügbar.';
      if (props.product.limitedEdition)
        return `Noch ${currentVariant.value.unitsLeft || ''} Stück verfüghbar`;
      return props.product.label;
    });

    const filterProductsAbove150UserPoints = (product: Product): boolean =>
      product.requiredPoints <= props.userPoints + 150;
    const filterCurrentProduct = (product: Product): boolean =>
      product.productId !== props.product.productId;
    const filterNonITGProduct = (product: Product): boolean =>
      PRODUCT_TYPES.ITG === product.type;

    const filterCarouselProducts = (product: Product): boolean =>
      filterCurrentProduct(product) &&
      filterProductsAbove150UserPoints(product) &&
      filterNonITGProduct(product);

    const sendGTM = () => {
      const productGTM = {
        ...productFieldDefault,
        name: props.product.name,
        id: props.product.productId,
        price: pointsInEuro(props.product.requiredPoints),
        category: props.product.category,
        variant: currentVariant.value.name,
        brand: brandGTM(props.product.type),
        quantity: 1,
      };
      pushDataLayer('productAddCart', { productList: [productGTM] });
    };

    onMounted(() => {
      event('Product_View', {
        event_category: 'ecommerce',
        event_label: `${props.product.name} | ${currentVariant.value.name}`,
        value: props.product.requiredPoints,
      });
    });

    const handleOutOfStockPopup = (val) => {
      notificationPopup.value = val;
      sendGTM();
    };

    const priorityTierMap = {
      silver: 1,
      gold: 2,
      platinum: 3,
    };

    const requiredTierConfig = computed(() => {
      const tierConfig = {
        silver: {
          priority: priorityTierMap.silver,
          background: '',
          paddings: 'px-0',
          button: '',
          labelText: '',
          labelColor: '',
          button1Props: { theme: 'dark' },
          button2Props: { theme: 'light' },
          border: 'border-tints-slate-15',
        },
        gold: {
          priority: priorityTierMap.gold,
          background:
            getTierPriority(props.currentTier) >= priorityTierMap.gold
              ? null
              : 'bg-secondary-yellow',
          paddings: 'px-3.5 sm:px-m lg:px-l',
          button: 'bg-transparent',
          labelText: 'Gold Produkt',
          labelColor: theme.colors['primary-soft-white'],
          button1Props: { theme: 'dark' },
          button2Props: { theme: 'light' },
          border: 'border-primary-slate',
        },
        platinum: {
          priority: priorityTierMap.platinum,
          background:
            getTierPriority(props.currentTier) >= priorityTierMap.platinum
              ? null
              : 'bg-secondary-platinum text-primary-soft-white',
          paddings: 'px-3.5 sm:px-m lg:px-l',
          button: 'bg-transparent',
          labelText: 'Platin Produkt',
          labelColor: theme.colors['primary-soft-white'],
          button1Props: { theme: 'light', outline: true },
          button2Props: { theme: 'dark' },
          border: 'border-tints-slate-65',
        },
      };

      return tierConfig[props.product.requiredTier];
    });

    const getTierPriority = (tier) =>
      priorityTierMap[tier] || priorityTierMap.silver;
    const isTierLocked = computed(
      () =>
        getTierPriority(props.product.requiredTier) >
        getTierPriority(props.currentTier)
    );
    const isGoldTierOrAbove = computed(
      () =>
        getTierPriority(props.product.requiredTier) >
        getTierPriority(priorityTierMap.silver)
    );

    return {
      activateNotification,
      currentImageOverlay,
      currentVariant,
      filterCarouselProducts,
      handleOutOfStockPopup,
      isGoldTierOrAbove,
      isItgAdditionalProduct,
      isItgProduct,
      isArvatoProduct,
      isOpenImageOverlay,
      isTierLocked,
      mainProductImages,
      notificationPopup,
      notifications,
      notificationStatus,
      openImageSliderOverlay,
      productLabelBadgeBgColor,
      productLabelBadgeText,
      requiredTierConfig,
      selectVariant,
      sendGTM,
      teasers,
      theme,
      shouldDisplayNotes,
    };
  },
});
