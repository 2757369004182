
import { defineComponent } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

const EVENT_OPEN_IMAGE_SLIDER_OVERLAY = 'onOpenImageSliderOverlay';

export default defineComponent({
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    sliderImages: {
      type: Array,
      default: () => [],
    },
    idRef: {
      type: String,
      required: true,
    },
    imageClasses: {
      type: String,
      default: '',
    },
    sliderClasses: {
      type: String,
      default: '',
    },
    bulletsOutside: {
      type: Boolean,
      default: true,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    fixedHeight: {
      type: Boolean || String,
      default: true,
    },
  },
  emits: [EVENT_OPEN_IMAGE_SLIDER_OVERLAY],
  data() {
    return {
      currentSlideID: null,
    };
  },
  methods: {
    setCurrentSlide(_eventName: string, params: { currentSlide: { index: string }}): void {
      this.currentSlideID = params.currentSlide.index;
    },
    showFullscreenSlider(): void {
      this.$emit(EVENT_OPEN_IMAGE_SLIDER_OVERLAY, this.currentSlideID);
    },
  },
});
